


























































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiTeamRecord, apiTeamCancel } from '@/api/marketing/combination'
import { PageMode } from '@/utils/type'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsPagination,
        ExportData,
        LsDialog
    }
})
export default class AccountLog extends Vue {
    /** S Data **/
    // 日期选择器
    pickerOptions = {
        shortcuts: [
            {
                text: '最近一周',
                onClick(picker: any) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    picker.$emit('pick', [start, end])
                }
            },
            {
                text: '最近一个月',
                onClick(picker: any) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    picker.$emit('pick', [start, end])
                }
            },
            {
                text: '最近三个月',
                onClick(picker: any) {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    picker.$emit('pick', [start, end])
                }
            }
        ]
    }
    tableData = []
    // 顶部查询表单
    pager: RequestPaging = new RequestPaging()
    // 顶部查询表单
    form = {
        name: '', // 活动名称
        goods_info: '', // 商品信息，支持商品名称、商品编码
        user_info: '', // 用户信息，支持用户昵称、用户编号
        activity_info: '', // 活动名称
        status: ' ', // 砍价记录状态
        start_time: '',
        end_time: ''
    }

    apiTeamRecord = apiTeamRecord
    /** E Data **/

    /** S Methods **/
    splitTime() {
        if (this.tableData != null) {
            this.form.start_time = this.tableData[0]
            this.form.end_time = this.tableData[1]
        }
    }
    // 重置
    onReset() {
        this.form = {
            name: '',
            goods_info: '', // 商品信息，支持商品名称、商品编码
            user_info: '', // 用户信息，支持用户昵称、用户编号
            activity_info: '', // 活动名称
            status: ' ', // 砍价记录状态
            start_time: '',
            end_time: ''
        }
        this.tableData = []
        this.getList()
    }
    // 记录
    getList(page?: number): void {
        page && (this.pager.page = page)
        this.pager
            .request({
                callback: apiTeamRecord,
                params: {
                    ...this.form
                }
            })
            .then((res: any) => {})
    }

    // 结束砍价
    onStop(id: any) {
        apiTeamCancel({
            id: id
        }).then((res: any) => {
            this.getList()
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getList()
    }
    /** E Life Cycle **/
}
